/* Menu.css */
.menu-section {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: 'Rubik', sans-serif;
  color: White;
}

.accordion-item {
  margin-bottom: 10px;
  border: 1px solid Black;
  border-radius: 5px;
  background-color: #2b2d3d;
}

.accordion-button {
  width: 100%;
  padding: 15px;
  text-align: left;
  font-size: 18px;
  font-family: 'Georgia', serif;
  font-weight: bold;
  background-color: #2b2d3d;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.accordion-button:hover {
  background-color: #2b2d3d;
}

.accordion-content {
  padding: 15px;
}

.pdf-document {
  display: block;
  margin: auto;
  border: none;
}

.react-pdf__Page__annotations {
  display: none;
}
