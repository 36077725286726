.booking-banner {
    background-color: #CA9721;
    color: white;
    text-align: center;
    padding: 12px 20px;
    font-family: 'Georgia', serif;
    font-size: 18px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 99;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    box-sizing: border-box;
    left: 0; /* Ensure it spans the full width */
}

.booking-banner p {
    margin: 0;
    line-height: 1.4;
}

.booking-banner a {
    color: white;
    font-weight: bold;
    text-decoration: underline;
}

.booking-banner a:hover {
    color: #1b1e28;
}

.banner-highlight {
    font-weight: bold;
}

@media (max-width: 768px) {
    .booking-banner {
        padding: 8px 10px;
        font-size: 14px;
        position: sticky;
        top: 0;
        display: block !important;
        visibility: visible !important;
        width: 100% !important;
    }
}