@import url('https://fonts.googleapis.com/css2?family=Amaranth:wght@700&family=Playfair+Display:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Georgia&display=swap');  /* If you haven't already imported 'Georgia', since it's also being used */

.App {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding-top: 170px; /* Increased padding to accommodate the banner */
  width: 100%;
  box-sizing: border-box;
  min-height: 100vh;
}


.woodroffe-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: normal;
}

.woodroffe-description {
  flex: 1;
  font-family: 'Georgia', serif;
  font-size: 18px;
  line-height: 1.6;
  color: white;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
  max-width: 800px;
  text-align: center;
  margin-left: 50px;

}

.woodroffe-image {
  flex-shrink: 0;
  margin-left: 20px;
  width: 450px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.woodroffe-image-reduced {
  width: 325px; /* Overrides the width from .woodroffe-image class */
  margin-left: 120px;
}

.App-header {
  display: flex;
  align-items: center;
  background-color: #1b1e28;
  padding: 5px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100000;
}

.App-logo {
  height: 100px;
  margin-right: 20px;
}

nav {
  display: flex;
  justify-content: space-between;
  width: 75%;
  padding: 0 20px;
}

nav a {
  color: white;
  text-decoration: none;
  padding: 5px 7.5px;
  border-radius: 5px;
  transition: background-color 0.3s;
  flex: 1;
  text-align: center;
}

nav a:first-child {
  border-left: none;
}

nav a:hover {
  background-color: #444;
}

h1 {
  text-align: center;
  font-family: 'Amaranth', sans-serif;
  color: #CA9721;
  margin-bottom: 10px;
  font-size: 2.5em;
}

#home {
  padding: 20px;
  border-radius: 10px;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.gallery img {
  width: 250px;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.gallery img:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .App {
    padding-top: 120px; /* Updated for mobile with banner */
  }

  .woodroffe-section {
    flex-direction: column;
  }

  .woodroffe-description {
    max-width: 100%;
    margin: 10px 0;
  }

  .woodroffe-image {
    width: 100%;
    margin-left: 0;
  }

  .App-header {
    flex-direction: column;
  }

  .App-logo {
    height: 50px;
    margin-right: 0;
    margin-bottom: 10px;
  }

  nav {
    flex-direction: column;
    padding: 10px 0;
  }

  nav a {
    flex: none;
    margin-bottom: 5px;
  }

  h1 {
    font-size: 2.7em;
  }

  .gallery {
    flex-direction: column;
  }

  .gallery img {
    width: 100%;
  }
}
.menu-toggle {
  display: none;
  cursor: pointer;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons i {
  font-size: 1em; /* Adjust this value to make the icons bigger or smaller */
}


.social-icons a {
  color: white;
  text-decoration: none;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  nav {
    display: none;
  }

  nav.open {
    display: flex;
    flex-direction: column;
  }

  .App-header {
    justify-content: space-between;
  }

  .social-icons {
    position: absolute;
    right: 10px;
  }
}
.menu-section {
  padding: 20px;
  text-align: center;
  font-family: 'Playfair Display', serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Menu Table Styles */
.menu-table {
  width: 80%;
  border-collapse: collapse;
  background-color: #1b1e28;
  color: white;
  font-size: 18px;
  border-radius: 5px;
  overflow: hidden; /* Added to support border-radius on table */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.menu-table thead {
}

.menu-table th {
  padding: 15px;
  color: #CA9721;
  text-align: left;
  border-bottom: 1px solid #444;
  font-family: 'Amaranth', sans-serif;
}

.menu-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #444;
  font-family: 'Playfair Display', serif;
}

.menu-table tr:last-child td {
  border-bottom: none;
}

.menu-table tr:hover {
}

.menu-item-price {
  font-weight: bold;
  text-align: right;
}

.App-header.hide {
  display: none;
}

/* Adjust the size of the menu button and the logo for mobile view */
@media (max-width: 768px) {
  .App-logo {
    height: 60px;
  }

  .menu-toggle {
    font-size: 20px;
  }
}
.book-table-section {
  text-align: center;
  margin: 20px 0;
}

.book-table-section .book-table-button {
  text-decoration: none;
}

.book-table-section button {
  background-color: Black;
  color: white;
  padding: 10px 20px;
  border: none;
  Font-Family: 'Georgia', serif;
  border-radius: 5px;
  width: 200px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.book-table-section button:hover {
  background-color: #A0522D;
}